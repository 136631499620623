import React from 'react';
import {
  PageLayout,
  PageLayoutHeader,
  PageLayoutBody,
  PageTitle,
} from '../components/page-layout';
import SEO from '../components/seo';

const Biography = () => (
  <PageLayout className="biography-background">
    <SEO title="Biography" />
    <PageLayoutHeader />
    <PageTitle>Biography</PageTitle>
    <PageLayoutBody className="biography-content">
      <h2 className="small-title">{'Who is Gustavo?'}</h2>
      <p>
        Having performed with world renowned artists such as Alejandro Sanz,
        Susana Baca, Joyce Moreno, and Rosa Pasos, Gustavo began his journey as
        a talented pianist at the age of 6 in Lima, Peru. As a pre-teen, he
        performed around Lima and began to make a name for himself as a skilled
        classical pianist. As a teenager, he accepted the only spot available
        for a pianist at the National Conservatory of Music in Lima.
      </p>
      <p>
        Shortly after graduation, Gustavo moved to the US, where he expanded his
        repertoire to include jazz, R&B, Brazilian, and Latin music. He
        performed with local musicians in Kansas for a few years before
        receiving a scholarship to attend Berklee College of Music in Boston,
        MA. At Berklee, he honed his jazz and Latin skills, flexed his creative
        muscles into other styles, and studied under the distinguished Oscar
        Stagnaro, Alain Mallet, and Ray Santisi, among others.
      </p>
      <p>
        In the past four years, he recorded five full albums and participated in
        numerous sessions, performing in a variety of styles. Samples can be
        found in his portfolio.
      </p>
    </PageLayoutBody>
  </PageLayout>
);

export default Biography;
